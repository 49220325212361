<template>
  <div class="auth-wrapper auth-v2">
    <div
      class="auth-inner"
      style="background: #93278F"
    >
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-brand-logo pt-4">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  :src="appLogoForgot"
                  max-height="100%"
                  max-width="100%"
                  alt="logo"
                  contain
                  class="justify-center"
                ></v-img>
              </div>
            </div>
          </div>
          <div class="auth-illustrator-wrapper">
            <!-- rounded bg -->
            <img
              class="auth-mask-bg"
            />
            <v-img
              class="auth-mask-bg"
              :src="require(`@/assets/images/shapes/rounded.png`)"
            >
            </v-img>
            <!-- rounded bg -->
            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="500"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/ari.png`)"
              ></v-img>
            </div>
            <!-- 3d character -->
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Forgot Password? 🔒
                  </p>
                  <p class="mb-2">
                    Enter your email and we'll send you instructions to reset your password
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="form"
                    @submit.prevent="handleSendResetLink"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="john@example.com"
                      hide-details
                      :error-messages="errorMessages.email"
                      :rules="[validators.required]"
                      class="mb-4"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      :loading="isLoadingButton"
                      :disabled="isLoadingButton"
                      type="submit"
                      large
                    >
                      Send reset link
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { required, emailValidator } from '@core/utils/validation'
import store from '@/store'
import { useRouter } from '@core/utils'

export default {
  setup(props, context) {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const isLoadingButton = ref(false)
    const form = ref(null)
    const errorMessages = ref({})
    const { router } = useRouter()

    const showSnackbar = snackbarData => {
      store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const handleSendResetLink = async () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      const data = {
        email: email.value,
      }

      isLoadingButton.value = true
      await context.root.$services.AuthServices.forgotPassword(data).then(
        res => {
          showSnackbar({
            color: 'success',
            text: res.data.result.message,
          })
          router.push('/login')
        },
        err => console.error(err),
      )
      isLoadingButton.value = false
    }

    return {
      isPasswordVisible,
      email,
      isLoadingButton,
      handleSendResetLink,
      errorMessages,
      form,
      showSnackbar,

      // validation
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogoForgot: themeConfig.app.logoForgot,

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
